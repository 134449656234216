export const FETCH_QUIZZES_START = 'FETCH_QUIZZES_START'
export const FETCH_QUIZZES_SUCCESS = 'FETCH_QUIZZES_SUCCESS'
export const FETCH_QUIZZES_ERROR = 'FETCH_QUIZZES_ERROR'
export const FETCH_QUIZ_SUCCESS = 'FETCH_QUIZ_SUCCESS'

export const QUIZ_SET_STATE = 'QUIZ_SET_STATE'
export const FINISH_QUIZ = 'FINISH_QUIZ'
export const QUIZ_NEXT_QUESTION = 'QUIZ_NEXT_QUESTION'
export const QUIZ_RETRY = 'QUIZ_RETRY'

export const CREATE_QUIZ_QUESTION = 'CREATE_QUIZ_QUESTION'
export const RESET_QUIZ_CREATION = 'RESET_QUIZ_CREATION'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
